export default{
    data(){
        return{
            userAgreement:''
        }
    },
    created(){
        this.getUserAgreement()
    },
    methods:{
        backIndex: function () {
            this.$router.replace('/')
        },
        toForget: function () {
            this.$router.push({ path: "/forget-password" });
        },
        submitForm: function () {

        },
        success:function(){
            this.verifi = true
        },
        open: function () {
            this.show = true;
        },
        close: function () {
            this.show = false;
        },
        getUserAgreement:function(){
            this.$post('/api/user/getUserAgreement.html',this.loginForm ).then((res) => {
                if(res.code == 200){
                  this.userAgreement = res.data || ''
                }else{;
                    return this.$message(res.msg); 
                }
       
            }).finally(() => {

            })
        }
    }
}