export default {
    data() {
        return {
            loginForm: {
                mobile: '',
                pwd: ''
            },
            show: false,
            verifi:false,
            logourl:'',
            logo_img:'',
            logo_message:'',
            shop_address: "北京市朝阳区上线6号",
            shop_beian: "京ICP备2021018702号",
            shop_email: "JQ@madeinjq.com",
            shop_name: "北京矜群科技有限公司",
        }
    },
    created(){
        this.getLogo();
        this.getLoginLogo();
        this.getAbout();
    },
    methods: {
        login: function (e) {
            // let loginForm = {
            //     mobile:this.mobile,
            //     pwd: this.pwd
            // }
            // if(!this.verifi){
            //     return this.$message('请先进行滑动验证');
            // };
            this.$post('/api/user/login.html',this.loginForm ).then((res) => {
                if(res.code == 200){
                    if(res.data.user_type == 3){
                        if(res.data.audit_status == 1){
                            localStorage.setItem('token',res.data.shop_token);
                            localStorage.setItem('shopToken',res.data.shop_token);
                            localStorage.setItem('mobile',res.data.mobile);
                            localStorage.setItem('supplier_id',res.data.id);
                        }else{
                            localStorage.setItem('token','');
                        }
                        window.open(this.url+'/shopweb/','_blank');
                        return;
                    }
                    localStorage.setItem('user',JSON.stringify(res.data))
                    localStorage.setItem('yhtoken',res.data.token)
                    localStorage.setItem('isLogin',1);
                    this.$store.commit('changeLogin',1)
                    this.backIndex();
                }else{

                    return this.$message(res.msg); 
                }
       
            }).finally(() => {

            })
        },
        getLogo:function(){
            this.$post('/api/base/getLogo.html', {}).then((res) => {
                console.log(res)
                if(res.code == 200){
                   this.logourl = res.url || ''
                }else{
                    return this.$message(res.msg); 
                }
            }).finally(() => {
        
            })
        },
        getLoginLogo:function(){
            this.$post('/api/index/getLoginLogo.html', {}).then((res) => {
                console.log(res)
                if(res.code == 200){
                   this.logo_img = res.data.logo_img || '';
                   this.logo_message  = res.data.logo_message || ''
                }else{
                    return this.$message(res.msg); 
                }
            }).finally(() => {
        
            })
        },
        getAbout: function () {
            this.$post('/api/base/abountUs.html', {}).then((res) => {
                if (res.code == 200) {
                    this.shop_address = res.data.shop_address || '';
                    this.shop_beian = res.data.shop_beian || '';
                    this.shop_email = res.data.shop_email || '';
                    this.shop_mobile = res.data.shop_mobile || '';
                    this.shop_name = res.data.shop_name || '';
                    this.shop_statemen = res.data.shop_statemen || '';
                    this.shop_xy = res.data.shop_xy || ''
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
    }
}