<template>
  <div class="login">
    <div class="c-width">
      <div class="top cf">
        <router-link class="fl" to="/">
          <div class="logo">
            <img :src="logourl" alt="">
          </div>
        </router-link>
        <div class="back-index fr" @click="backIndex">
          <img
            src="../../../assets/images/common_iocn_shouye.png"
            alt=""
            srcset=""
          />
          <span>返回首页</span>
        </div>
      </div>
      <div class="logo-box cf">
        <div class="l-box fl">
          <div class="in-box">
            <img :src="logo_img" alt="" />
            <p>
              {{logo_message}}
            </p>
          </div>
        </div>
        <div class="fl r-box">
          <div class="f-box cf">
            <h6 class="fl">账号登录</h6>
            <p class="fr">还没有账号？<router-link class="to-reg" to="/register" >立即注册</router-link></p>
          </div>
          <el-form :model="loginForm" status-icon class="formData">
            <el-form-item class="form-item cf">
              <img src="../../../assets/images/denglu_iocn_shouji.png" alt="" />
              <el-input
                placeholder="请输入手机号"
                v-model="loginForm.mobile"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item cf password">
              <img src="../../../assets/images/denglu_iocn_mima.png" alt="" />
              <el-input
                type="password"
                placeholder="请输入密码"
                v-model="loginForm.pwd"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <div class="forget-password" @click="toForget">忘记密码？</div>
<!--            <vue-simple-verify-->
<!--              ref="verify"-->
<!--              @success="success"-->
<!--              tips="滑到底验证"-->
<!--              movedColor="#007DB8"-->
<!--              height="40"-->
<!--              width="320"-->
<!--            />-->
            <el-form-item class="btn-item">
              <el-button type="primary" @click="login">登录</el-button>
            </el-form-item>
          </el-form>
          <div class="agreement-box cf">
            <img
              class="fl"
              src="../../../assets/images/tzsc_iocn_xz2.png"
              alt=""
            />
            <p class="fl">
              登录即为同意
              <span @click="open"> 《共享制造在线服务协议》 </span>
            </p>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p>
          <a href="https://beian.miit.gov.cn/" style="color: #FFFFFF;" >{{shop_beian}}</a> 共享制造在线版权所有 Copyright 2021 {{shop_name}}
        </p>
        <p>
          公司地址: {{shop_address}}  举报邮箱: {{shop_email}}
        </p>
        <p> </p>
      </div>
    </div>
    <div class="alert-box" v-if="show">
      <h5>《共享制造在线服务协议》</h5>
      <div class="context" v-html="userAgreement">       
  </div>
    <div class="close-btn" @click="close">阅读并同意该协议</div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
import VueSimpleVerify from "vue-simple-verify";
import "../../../../node_modules/vue-simple-verify/dist/vue-simple-verify.css";
export default {
  mixins,
  components: {
    VueSimpleVerify,
  },
};
</script>
<style scoped>
@import "../login.css";
</style>